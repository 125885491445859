import Vue from "vue";
import Router from "vue-router"

// import index from "./view/index.vue"
import goods from "./view/goods.vue"
import order from "./view/order.vue"
import user from "./view/user.vue"
import purcharse from "./view/purcharse.vue"
import article from "./view/article.vue"
import articleList from "./view/articleList.vue"
import orderUsage from "./view/order_usage.vue"
import finishFreeGoodsUsage from "./view/finish_free_goods_usage.vue"
import orderUsageAdd from "./view/order_usage_add.vue"
import usageForCoach from "./view/usage_for_coach.vue"
import usageForCoachRecorder from "./view/usage_for_coach_recorder.vue"
import createFreeOrderUsage from "./view/create_free_order_usage.vue"
import script from "./view/script.vue"
import articles from "./view/articles.vue"
import login from "./view/login.vue"
import bindPhone from "./view/bind_phone.vue"
import coachSalary from "./view/coach_salary.vue"
import adminFinishUsage from "./view/admin_finish_usage.vue"
import adminRecorders from "./view/admin_recorders.vue"

Vue.use(Router)

const MP_NAME = "京开车"

var routes = [
    {
        path: "",
        name: "goods",
        component: goods,
        meta: {
            title: `${MP_NAME} - 商品`
        }
    },
    {
        path: "/order",
        name: "order",
        component: order,
        meta: {
            title: `${MP_NAME} - 订单`
        }
    },
    {
        path: "/order/:orderId/usage",
        name: "order",
        component: orderUsage,
        meta: {
            title: `${MP_NAME} - 使用记录`
        }
    },
    {
        path: "/order/:orderId/usages/:usageId/finish_free_goods_usage",
        name: "order",
        component: finishFreeGoodsUsage,
        meta: {
            title: `${MP_NAME} - 使用记录`
        }
    },
    {
        path: "/order/:orderId/usage_add",
        name: "order",
        component: orderUsageAdd,
        meta: {
            title: `${MP_NAME} - 预约`
        }
    },
    {
        path: "/user",
        name: "user",
        component: user,
        meta: {
            title: `${MP_NAME} - 用户`
        }
    },
    {
        path: "/articles",
        name: "user",
        component: articles,
        meta: {
            title: `${MP_NAME} - 使用帮助`
        }
    },
    {
        path: "/coach_salary",
        name: "user",
        component: coachSalary,
        meta: {
            title: `${MP_NAME} - 我的收益`
        }
    },
    {
        path: "/purcharse/:goodsId",
        name: "purcharse",
        component: purcharse,
        meta: {
            title: `${MP_NAME} - 购买`
        }
    },
    {
        path: "/article/:articleId",
        name: "article",
        component: article,
        meta: {
            title: `${MP_NAME} - 帮助`
        }
    },
    {
        path: "/article",
        name: "articleList",
        component: articleList,
        meta: {
            title: `${MP_NAME} - 帮助`
        }
    },
    {
        path: "/usage_for_coach",
        name: "order",
        component: usageForCoach,
        meta: {
            title: `${MP_NAME} - 订单`
        }
    },
    {
        path: "/usage_for_coach/:id/recorder",
        name: "order",
        component: usageForCoachRecorder,
        meta: {
            title: `${MP_NAME} - 订单服务中`
        }
    },
    {
        path: "/create_free_order_usage",
        name: "user",
        component: createFreeOrderUsage,
        meta: {
            title: `${MP_NAME} - 创建基础课`
        }
    },
    {
        path: "/script/:scriptName",
        name: "script",
        component: script,
        meta: {
            title: `${MP_NAME} - 快捷方式`
        }
    },
    {
        path: "/login",
        name: "user",
        component: login,
        meta: {
            title: `${MP_NAME} - 登录`
        }
    },
    {
        path: "/bind_phone",
        name: "user",
        component: bindPhone,
        meta: {
            title: `${MP_NAME} - 绑定手机号`
        }
    },
    {
        path: "/admin/finish_usage",
        name: "user",
        component: adminFinishUsage,
        meta: {
            title: `${MP_NAME} - 强制完成预约`
        }
    },
    {
        path: "/admin/recorders",
        name: "user",
        component: adminRecorders,
        meta: {
            title: `${MP_NAME} - 录音`
        }
    },
]

const router = new Router({
    mode: "history",
    routes: routes
})

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || MP_NAME;
    });
});

export default router
