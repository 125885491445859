<template>
  <!-- 由于处理不好 model 的弹出动画，被迫新开一个页面，实属无奈 -->
  <div class="page">
    <div id="dialogs">
        <div class="js_dialog" role="dialog"  aria-hidden="true" aria-modal="true" aria-labelledby="js_title1" id="iosDialog1" style="display: none;">
          <div class="weui-mask"></div>
          <div class="weui-dialog">
              <div class="weui-dialog__hd"><strong class="weui-dialog__title" id="js_title1">请上传核销二维码 {{ uploadProgress }}</strong></div>
              <div class="weui-cells__group weui-cells__group_form weui-cells_checkbox" style="margin-bottom: 16px">
                  <label class="weui-cell weui-cell_active weui-check__label" for="user-agreement">
                      <input class="uploader_input" type="file" @change="addFile" accept="image/*" style="border: 2px dashed gray; border-radius:5px;padding:15px 20px;width:-webkit-fill-available" />
                  </label>
              </div>
              <div class="weui-dialog__ft">
                  <a role="button" href="javascript:" v-on:click="cancel" class="weui-dialog__btn weui-dialog__btn_default">取消</a>
                  <a role="button" href="javascript:" v-on:click="confirm" class="weui-dialog__btn weui-dialog__btn_primary">{{ confirmButtonText }}</a>
              </div>
          </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from 'jquery'
import { uploadQRCode } from '../js/order_usage'
import { finishUsage } from '../js/order_usage';

var uploadProgress0 = "";
var uploadProgressIng = "（上传中...）";
var uploadProgressDone = "（上传完毕）";
var uploadProgressFail = "（上传失败）";
var confirmButtonText0 = "完成基础课";
var confirmButtonTextIng = "正在提交... ";

export default {
  name: "finishFreeGoodsUsage",
  props: {
    uploadProgress: {
      type: String,
      default: uploadProgress0
    },
    uploadSuccessful: {
      type: Boolean,
      default: false
    },
    confirmButtonText: {
      type: String,
      default: confirmButtonText0
    },
    confirmIng: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.show();
  },
  methods: {
    show: function() {
      var $iosDialog1 = $('#iosDialog1');
      $iosDialog1.fadeIn(200);
      $iosDialog1.attr('aria-hidden','false');
      $iosDialog1.attr('tabindex','0');
      $iosDialog1.trigger('focus');
    },
    hide: function() {
      var $iosDialog1 = $('#iosDialog1');
      $iosDialog1.fadeOut(200);
      $iosDialog1.attr('aria-hidden','true');
      $iosDialog1.removeAttr('tabindex');
    },
    confirm: function() {
      if (this.uploadProgress == uploadProgress0) {
        alert('请先上传核销二维码');
        return;
      }
      if (this.uploadProgress == uploadProgressFail) {
        alert('上传核销二维码失败，请重新上传');
        return;
      }
      if (this.uploadProgress == uploadProgressIng) {
        alert('请等待二维码上传完毕');
        return;
      }
      if (this.confirmButtonText == confirmButtonTextIng) {
        return;
      }

      this.confirmButtonText = confirmButtonTextIng;

      var usageId = this.$route.params.usageId;
      var orderId = this.$route.params.orderId;
      finishUsage(usageId).then(function () {
        window.location.href = `/order/${orderId}/usage`;
      }).catch(function (err) {
        alert(err);
        this.confirmButtonText = confirmButtonText0;
      });
    },
    cancel: function() {
      var orderId = this.$route.params.orderId;
      window.location.href = `/order/${orderId}/usage`;
    },
    addFile: async function(e) {
      const maxSize = 10 * 1024 * 1024;
      var file = e.target.files[0];
      if (file.size > maxSize) {
        alert('文件大小不能超过10M');
        return;
      }

      e.target.disabled = true;

      this.uploadProgress =uploadProgressIng;
      var usageId = this.$route.params.usageId;
      try {
        await uploadQRCode(usageId, file);
        this.uploadProgress = uploadProgressDone;
      } catch (e) {
        this.uploadProgress = uploadProgressFail;
      }

      e.target.disabled = false;
    }
  }
};
</script>

<style>
</style>
